import { useStaticQuery, graphql } from "gatsby";
import { useEffect, useState } from "react";

export const useSearch = (keyword) => {
  const [results, setResults] = useState([]);
  const data = useStaticQuery(graphql`
    query {
      allContentfulProduct {
        edges {
          node {
            category {
              name
            }
            subCategory {
              name
            }
            name
            sku
            width
            length
            height
            image {
              gatsbyImageData(quality: 80)
              file {
                url
              }
            }
            startingPrice
            downPayment
            url
            metaTitle
            metaDescription
          }
        }
      }
    }
  `);

  const checkWithKeyword = (item, search) => {
    if (item.metaTitle.toLowerCase().includes(search.toLowerCase()))
      return true;
    if (item.name.toLowerCase().includes(search.toLowerCase())) return true;

    const justSku = item.sku.replace("BAM#", "");
    if (justSku.toLowerCase() === search.toLowerCase() || item.sku === search)
      return true;
    return false;
  };

  useEffect(() => {
    if (!keyword) return;
    const rs = (data.allContentfulProduct.edges || [])
      .map((p) => p.node)
      .filter((p) => checkWithKeyword(p, keyword));
    setResults([...rs]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  if (!keyword) return [];

  return results;
};
