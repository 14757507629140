import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Facebook from "../Icons/Facebook";
import Instagram from "../Icons/Instagram";
import Youtube from "../Icons/Youtube";
import Pinterest from "../Icons/Pinterest";
import PrimaryButton from "../Button/PrimaryButton";
import PhoneIcon from "../Icons/PhoneIcon";

const FooterWrap = styled.div`
  position: relative;
  padding: 220px 0 22px;
  .container {
    position: relative;
    z-index: 1;
  }

  @media (max-width: 480px) {
    .container {
      width: 100%;
    }
  }
`;
const FooterBg = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;
const FooterMenuWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1;
  border-bottom: 1px dashed #938888;
  padding-bottom: 10px;
  p {
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 8px;
  }
  a {
    display: block;
    font-size: 16px;
    font-family: "Eina03-Regular";
    line-height: 24px;
    margin-bottom: 10px;
    color: #d0d0d0;
    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    p {
      font-size: 16px;
    }
    a {
      font-size: 14px;
    }
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    p {
      font-size: 14px;
    }
    a {
      font-size: 13px;
    }
  }
  @media (max-width: 380px) {
    a {
      font-size: 12px;
    }
  }
`;
const BottomBar = styled.div`
  height: 2px;
  width: 52px;
  border-bottom: 2px solid #e43d3c;
  margin-bottom: 18px;
`;

const SocialMediaWrap = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-right: 35px;
  }
  .instagram {
    svg {
      width: 35px;
      height: 35px;
    }
  }

  .pinterest {
    svg {
      width: 35px;
      height: 35px;
    }
  }
`;
const FooterBottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
  line-height: 24px;
  @media (min-width: 576px) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (min-width: 768px) {
    justify-content: space-between;
    text-align: left;
  }
`;
const CopyRight = styled.div`
  position: relative;
  width: 100%;
  color: #fff;
  text-transform: uppercase;
  @media (min-width: 768px) {
    flex-basis: 0px;
    flex-grow: 1;
    max-width: 100%;
  }
`;
const FooterNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  @media (min-width: 768px) {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    justify-content: flex-end;
  }
`;
const FooterItemNav = styled.div`
  position: relative;
  padding: 0 12px;
  &.footer-item {
    + .footer-item {
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-left: -14px;
        background-color: #fff;
      }
    }
  }
  > a {
    color: #d0d0d0;
    &:hover,
    &:focus {
      color: #fff;
    }
  }
`;
const FooterCta = styled.div`
  background-color: #fff;
  box-shadow: 0px -5px 25px 0px rgb(0 75 155 / 25%);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  text-align: center;
  display: block;
  padding: 5px 15px;
  @media (min-width: 768px) {
    display: none;
  }
  a {
    display: block;
  }
  .btn {
    display: flex;
  }
`;

const Footer = ({ location }) => {
  return (
    <FooterWrap>
      <FooterBg>
        <StaticImage
          src="../../images/footer-bg.png"
          placeholder="blurred"
          alt="footer background"
        />
      </FooterBg>
      <div className="container">
        <FooterMenuWrap>
          <div>
            <p>Home</p>
            <BottomBar />
            <Link to="/portable-buildings">Portable Buildings</Link>
            <Link to="/steel-buildings">Steel Buildings</Link>
            <Link to="/red-iron-buildings">Red Iron Buildings</Link>
            <Link to="/pole-barns">Pole Barns</Link>
            <Link to="/gazebos">Gazebos</Link>
            <Link to="/greenhouses">Greenhouses</Link>
            <Link to="/screen-rooms">Screen rooms</Link>
          </div>
          <div>
            <p>Resources</p>
            <BottomBar />
            <Link to="/financing">Financing</Link>
            <Link to="/rto">Rent to Own</Link>
            <Link to="/faq">FAQs</Link>
            <Link to="/gallery">Gallery</Link>
            <Link to="/blog">Blogs</Link>
            <Link to="/reviews">Reviews</Link>
          </div>
          <div>
            <p>Company</p>
            <BottomBar />
            <Link to="/about-us">About Us</Link>
            <Link to="/help-and-info">Help and Info</Link>
            <Link to="/service-area">Service Area</Link>
            <Link to="/contact">Contact Us</Link>
          </div>
          <div className="social">
            <p>Social Media</p>
            <BottomBar />
            <SocialMediaWrap>
              <a
                href="https://www.facebook.com/BuildingsAndMore/?fref=ts"
                target="_blank"
                aria-label="facebook"
                rel="noreferrer"
              >
                <Facebook fill="#fff" />
              </a>
              <a
                href="https://www.youtube.com/channel/UC4gVU1Ei9nbWT6yNmYllSQQ"
                target="_blank"
                aria-label="youtube"
                rel="noreferrer"
              >
                <Youtube fill="#fff" />
              </a>
              <a
                href="https://www.instagram.com/buildingsandmorefl/"
                className="instagram"
                target="_blank"
                aria-label="instagram"
                rel="noreferrer"
              >
                <Instagram fill="#fff" />
              </a>
              <a
                href="https://www.pinterest.com/buildingsandmore/"
                className="pinterest"
                target="_blank"
                aria-label="pinterest"
                rel="noreferrer"
              >
                <Pinterest fill="#fff" />
              </a>
            </SocialMediaWrap>
            <a href="https://www.bbb.org/us/fl/lake-city/profile/portable-buildings/buildings-more-0403-1124279">
              <StaticImage
                src="../../images/accreditation.png"
                placeholder="blurred"
                alt="BBB accredited business"
              />
            </a>
          </div>
        </FooterMenuWrap>
        <FooterBottom>
          <CopyRight>
            © {new Date().getFullYear()} Buildings And More &nbsp; | &nbsp; All
            Rights Reserved.
          </CopyRight>
          <FooterNav>
            <FooterItemNav className="footer-item">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </FooterItemNav>
            <FooterItemNav className="footer-item">
              <Link to="/terms-and-conditions">Terms of Use</Link>
            </FooterItemNav>
          </FooterNav>
        </FooterBottom>
      </div>
      <FooterCta className="footer-cta">
        <a href="tel:3867556449">
          <PrimaryButton text="(386) 755 6449" icon={<PhoneIcon />} />
        </a>
      </FooterCta>
    </FooterWrap>
  );
};
export default Footer;
